<template>
  <div
    v-if="product"
    class="ProductCard"
    :class="{
      'desk:flex ': featured
    }"
  >
    <div
      class="relative  aspect-product"
      :class="{
        'w-full': !featured,
        'basis-1/2 shrink-0': featured
      }"
    >
      <div
        v-if="!image"
      >
        <img
          src="/icons/no-image.svg"
          class="object-cover"
          alt="no image"
        >
      </div>
      <div
        v-else
        class="w-full h-full flex items-center justify-center"
      >
        <nuxt-img
          :src="image"
          provider="norce"
          preset="plpimage"
          :sizes="sizes"
          class="max-w-[80%] max-h-[80%] h-auto w-auto"
          :alt="product.name"
          :loading="placement > 1 ? 'lazy':'eager'"
        />
      </div>

      <nuxt-link
        :to="link"
        :class="{
          'pointer-events-none': userStore.isLoggedIn && pointShop
        }"
        @click="track"
      >
        <div class="imageOverlay absolute top-0 left-0 w-full h-full" />
      </nuxt-link>

      <div v-if="userStore.canFavorizeProducts" class="absolute top-6 right-6">
        <FavoriteButton
          :product="product"
          :design="design"
        />
      </div>

      <div
        v-if="showQuickbuy"
        class="absolute desk:bottom-12 desk:right-12 bottom-8 right-8"
      >
        <button
          class="bg-darkest h-32 w-32 text-center flex items-center justify-center"
          :title="$t('quickbuy.button')"
          @click="openQuickbuy"
        >
          <img
            src="/icons/plus-inv.svg"
            class="w-24 h-24 inline-block"
            alt=""
          >
        </button>
      </div>
      <div
        v-else-if="userStore.isLoggedIn && pointShop"
        class="absolute desk:bottom-12 bottom-8 left-0 w-full text-center"
      >
        <PointShopAmount :user-price="userPrice" :product="product" />
      </div>

      <!-- flags, top left -->
      <div
        class="type-xs leading-single absolute top-8 left-8"
      >
        <div
          v-if="product.hasBulkDiscount"
          class="px-8 py-6"
        >
          {{ product.isGiftCampaign ? $t('buyMore.plp.gift-flag') : $t('buyMore.plp.flag') }}
        </div>
        <div
          v-for="flag in product.productCardDisplayFlags"
          :key="flag.code"
        >
          <div
            v-if="shouldShowFlag(flag.code)"
            :style="`background-color: ${flag.background}`"
            class="inline-block text-center leading-single px-8 py-6"
            :class="{
              'uppercase ml-8': flag.background,
              'text-lightest': isDarkFlag(flag.background),
              'type-tiny': flag.code === 'colorMap'
            }"
          >
            {{ flag.text }}
          </div>
        </div>
      </div>
      <!-- more variants - bottom left -->
      <div
        v-if="product.hasVariants && !pointShop"
        class="type-xs leading-single absolute bottom-8 left-8 p-8 pb-4"
        :class="{
          nextToQuickbuy: showQuickbuy
        }"
      >
        {{ $t('productList.product.hasVariants') }}
      </div>
    </div>
    <div
      class="px-12 pt-4 desk:px-8 desk:pt-12 pb-32"
      :class="{
        'w-full': !featured,
        'basis-1/2 shrink-0 bg-[#f6f6f6] desk:flex items-center': featured
      }"
    >
      <nuxt-link
        :to="link"
        :class="{
          'pointer-events-none': userStore.isLoggedIn && pointShop
        }"
        @click="track"
      >
        <div class="type-headline-xxs desk:type-headline-xs mb-4 border-b-[#d6d6d3] border-b inline-block">
          {{ product.brand.name }}
          <span class="hidden">{{ product.priceFetchPartNo }}</span>
        </div>

        <div class="type-xs desk:type-sm mb-8">{{ product.name }}</div>
        <div
          v-if="featured"
          class="type-sm mb-24"
          v-html="product.shortDescription"
        />

        <div v-if="product.extendedLayout" class="leading-single type-xs-medium desk:type-sm-medium">
          <div v-if="product.hasVariants">
            {{ $t('productPage.extended.shades', { num: product.partNumbers.length }) }}
          </div>
        </div>
        <div v-else>
          <div class="leading-single normal-case type-xs-medium desk:type-sm-medium ">
            <div
              v-if="userPrice.status === 'logginIn' || userPrice.status === 'pending'"
              class="priceLoadBox"
            >
              {{ product.priceInfo.priceBeforeVatDisplay.replace(/\d/g, '&nbsp;') }}
            </div>
            <div
              v-if="userPrice.status === 'notLoggedIn'"
              class="underline type-xs-medium desk:type-sm-medium"
              @click.prevent="showLogin"
            >
              {{ $t('price.notLoggedIn') }}
            </div>

            <div
              v-if="userPrice.status === 'error'"
              class="text-brightRed"
            >
              ---
            </div>

            <div
              v-if="userPrice.status === 'ok'"
            >
              <div
                class="leading-single "
                :class="{
                  'text-brightRed': isUserDiscounted
                }"
              >
                <span v-if="pointShop">
                  {{ userPrice.price?.pointsDisplay }}
                </span>
                <span v-else>
                  {{ userPrice.price?.priceBeforeVatDisplay }}
                </span>

                <div v-if="userPrice.price?.recommendedPriceDisplay" class="text-dark pt-12">
                  {{ $t('product.recommendedPrice.short') }} {{ userPrice.price?.recommendedPriceDisplay }}
                </div>
              </div>
              <p
                v-if="(userPrice.price?.isCustomerPrice && userPrice.price?.discountPercentage > 0) || userPrice.price?.isCampaignPrice"
                class="mt-6 type-xs leading-single text-darker"
              >
                {{ $t('productPage.price.original') }} {{ userPrice.price?.priceBeforeDiscountBeforeVatDisplay }}
                <span v-if="userPrice.price?.isCustomerPrice">
                  ({{ $t('productPage.price.yourDiscount') }} <span class="text-brightRed">{{ userPrice.price?.discountPercentage }}%</span>)
                </span>
              </p>
            </div>
          </div>
        </div>
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import FavoriteButton from '~/components/product/FavoriteButton.vue';
import { useUiStore } from '~/store/ui';
import { storeToRefs } from 'pinia';
import { ProductRaw } from '~/constants/types/algolia';
import Product, { ProductModel } from '~/models/product';
import { useCartStore } from '~/store/cart';
import { useGlobalContentStore } from '~/store/globalContent';
import { useUserStore } from '~/store/user';
import { isLight } from '~/util/colorUtil';
import PointShopAmount from '~/components/product/PointShopAmount.vue';
import { createDataLayerItem, sendDataLayer } from '~/composeables/useTracking';
import useLinkReplacer from '~/composeables/useLinkReplacer';
const cartStore = useCartStore();
const userStore = useUserStore();
const globalContent = useGlobalContentStore();
const uiStore = useUiStore();
const { listImageSize } = storeToRefs(uiStore);

const { productUrl } = useLinkReplacer();
const props = defineProps<{
  product: ProductRaw | ProductModel,
  enableQuickBuy: boolean,
  placement: number,
  design: 'standard' | 'featured' | 'small', // small looks like standard but more columns
  pointShop?: boolean,
  isUpsell?: boolean,
  useVariantImage?: boolean, // if the list from algolia is distinct = true, we show the variant image
}>();

defineEmits(['quickbuy']);

const product = props.product instanceof Product ? props.product : Product.create(props.product);
if (!(props.product instanceof Product)) {
  console.log('Using ProductCard from ProductRaw is not recommended');
}

const image = computed(() => {
  if (props.useVariantImage && product.variantImage) {
    return product.variantImage;
  }
  return product.listImage;
});

const featured = computed(()=> {
  return props.design === 'featured';
});

const link = computed(()=> {
  return productUrl(product.url);
});

const userPrice = computed(()=> {
  return props.pointShop ?
    userStore.getPrice(product.partNo, props.isUpsell) :
    userStore.getPrice(product.priceFetchPartNo, props.isUpsell);
});

const openQuickbuy = async() => {
  cartStore.setCurrentlyBuying(product, props.isUpsell);
  uiStore.setShowQuickBuy(true);
};

const track = () => {
  sendDataLayer(
    'select_item',
    [ createDataLayerItem(props.product) ],
    {
      'item_list_name': globalContent.currentStory.name,
      'item_list_id': globalContent.currentStory.id,
    }
  );
};

const sizes = computed(()=> {
  switch (listImageSize.value) {
    case 'lg':
      return 'mob375:100vw mob390:100vw mob414:100vw desk:250px';
    case 'sm':
      return 'mob375:184px mob390:191px mob414:203px desk:190px';
  }
  return 'mob375:184px mob390:191px mob414:203px desk:250px';
});

const showLogin = () => {
  uiStore.setShowSigninModal(true, true);
};

const isDarkFlag = (input: string | null | undefined) => {
  if (!input) {
    return false;
  }
  return !isLight(input);
};

const isUserDiscounted = computed(()=> {
  return userStore.isSalePrice(product.priceFetchPartNo);
});

const showQuickbuy = computed(()=> {
  return props.enableQuickBuy && userStore.isLoggedIn && !product.extendedLayout && !props.pointShop;
});

const shouldShowFlag = (code: string) => {
  if (code === 'onDemand') {
    const anyInStock = props.product.variantStockStatuses.some((s) => s.inStock > 0);
    return !anyInStock;
  }
  return true;
};

</script>

<style scoped lang="postcss">
.priceLoadBox {
  @apply inline-block;
  color: rgba(0,0,0,0);
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}

@keyframes spin {
  from {
    background-color: #DEDDD9;
  }
  to {
    background-color: #F5F4F0;
  }
}

.nextToQuickbuy {
  /* 100% - padding - button size - margin to button */
  max-width: calc(100% - 16px - 32px - 4px);
}

</style>
